import axios from 'axios';
import { omit } from 'lodash';
import { getPartnerShortCode, responseEntityMapper, responseListMapper } from '@qwealth/qcore';
import {
  getPerson,
  IAddressDto,
  IHouseholdDto,
  IHouseholdMemberDto,
  IPerson,
  IPersonDto,
  IPartner,
  HouseholdMember,
  loadLegalEntity
} from '@qwealth/qdata';
import { defaultDemographicFormData } from '@qwealth/kyc';
import { putNotification } from './notifications';
import {
  errorHandler,
  getHouseholdId,
  notifyError,
  REACT_APP_QWEALTH_API,
} from '../../services/axiosService';
import { AppThunkAction } from '../store';

export const RESET_HOUSEHOLD = 'RESET_HOUSEHOLD';
export const PUT_HOUSEHOLD = 'PUT_HOUSEHOLD';
export const PUT_HOUSEHOLD_MEMBER = 'PUT_HOUSEHOLD_MEMBER';
export const PUT_HOUSEHOLD_PARTNER = 'PUT_HOUSEHOLD_PARTNER';
export const REMOVE_HOUSEHOLD_MEMBER = 'REMOVE_HOUSEHOLD_MEMBER';
export const HOUSEHOLD_INITIALIZED = 'HOUSEHOLD_INITIALIZED';

const resetHousehold = () => ({ type: RESET_HOUSEHOLD });

const putHousehold = (household: IHouseholdDto) => ({ type: PUT_HOUSEHOLD, household });
const putMember = (person: IPerson) => ({ type: PUT_HOUSEHOLD_MEMBER, person });
const putPartner = (partner: IPartner) => ({ type: PUT_HOUSEHOLD_PARTNER, partner });
const removeMember = (personQID: string) => ({ type: REMOVE_HOUSEHOLD_MEMBER, person: { QID: personQID } });
const setInitialized = (householdId: string) => ({ type: HOUSEHOLD_INITIALIZED, householdId });

export const getAddressId = (memberDto: IHouseholdMemberDto): string => {
  if (memberDto.memberType === 'Primary Client') {
    return memberDto.householdQID;
  }
  return memberDto.memberQID || '';
};

const loadPerson =
  (memberDto: IHouseholdMemberDto): AppThunkAction =>
  (dispatch) => {
    return Promise.all([
      axios
        .get(`${REACT_APP_QWEALTH_API}/persons/${memberDto.memberQID}`)
        .then((resp) => responseEntityMapper<IPersonDto>(resp)),
      axios
        .get(`${REACT_APP_QWEALTH_API}/persons/${getAddressId(memberDto)}/addresses`)
        .then((resp) => responseEntityMapper<IAddressDto>(resp, false))
    ])
      .then(([personDto, addressDto]) => personDto ? getPerson(personDto, addressDto, undefined) : null)
      .then((person) => person && dispatch(putMember(person)))
      .catch(errorHandler(dispatch));
  };

export const loadHousehold = (): AppThunkAction => {
  const householdId = getHouseholdId();
  if (!householdId) {
    return (dispatch) => dispatch(putNotification('Missing household param in url', 'Error'));
  }

  const partnerShortCode = getPartnerShortCode(householdId);
  return (dispatch) => {
    dispatch(resetHousehold());
    return Promise.all([
      axios
        .get(`${REACT_APP_QWEALTH_API}/households/${householdId}`)
        .then((resp) => responseEntityMapper<IHouseholdDto>(resp))
        .then((household) => dispatch(putHousehold(household))),
      axios
        .get(`${REACT_APP_QWEALTH_API}/partner?partnerShortCode=${partnerShortCode}`)
        .then((res) => responseEntityMapper<IPartner>(res))
        .then((partner) => dispatch(putPartner(partner))),
      axios
        .get(`${REACT_APP_QWEALTH_API}/households/${householdId}/members`)
        .then((resp) => responseListMapper<IHouseholdMemberDto>(resp))
        .then((members) =>
          Promise.all(
            members.map((member: IHouseholdMemberDto) =>
              ['Corporate', 'Trust'].includes(member.memberType)
                ? dispatch(loadLegalEntity(member.legalEntityQID as string))
                : dispatch(loadPerson(member))
            )
          )
        )
    ])
      .then(() => dispatch(setInitialized(householdId)))
      .catch(errorHandler(dispatch));
  };
};

export const createMember = (person: IPerson, householdQID: string, iaCode?: string, insuranceCode?: string): AppThunkAction =>
  (dispatch) => {
    const partnerShortCode = getPartnerShortCode(householdQID);

    return axios
      .post(`${REACT_APP_QWEALTH_API}/contact`, {
        ...omit(person, 'nbinBankAccountDetails'),
        partnerShortCode,
        householdQID,
        iaCode,
        insuranceCode,
        gender: person.gender === defaultDemographicFormData.gender
          ? undefined
          : person.gender,
        maritalStatus: person.maritalStatus === defaultDemographicFormData.maritalStatus
          ? undefined
          : person.maritalStatus,
        contactType: 'Household Member',
      })
      .then(response => responseEntityMapper<IPersonDto>(response))
      .then((savedPerson) => {
        dispatch(putMember(person));
        return savedPerson.QID
      })
      .catch(error => dispatch(notifyError(error)));
  };

export const removeHouseholdMember = (memberId: number, personQID: string): AppThunkAction => (dispatch) => axios
  .delete(`${REACT_APP_QWEALTH_API}/households/members/${memberId}`)
  .then(() => dispatch(removeMember(personQID)))
  .catch(errorHandler(dispatch, 'Could not remove contact from household.'));

// TODO: deprecate this once QWAPI supports saving memberType on contact
export const saveHouseholdMember = (member: Partial<HouseholdMember>): AppThunkAction => dispatch => axios
  .patch(`${REACT_APP_QWEALTH_API}/households/members/${member.id}`, { memberType: member.memberType})
  .catch(errorHandler(dispatch, 'Could not update household member type'));

export const addContactToHousehold =
  (person: IPersonDto, memberType: string): AppThunkAction =>
  (dispatch) => {
    try {
      const householdQID = getHouseholdId();
      if (!householdQID) {
        throw new Error('Invalid Household');
      }
      const householdMember = {
        householdQID,
        memberType
      };

      const QID = person.QID as string;

      const { contactEmail } = person;
      return axios
        .patch(`${REACT_APP_QWEALTH_API}/persons/${QID}/`, { contactEmail, QID })
        .then(() => {
          const promises: Array<Promise<any>> = [
            axios.post(
              `${REACT_APP_QWEALTH_API}/persons/${QID}/households/`,
              householdMember
            )
          ];
          return Promise.all(promises);
        })
        .then(() => dispatch(loadPerson({ memberQID: QID, memberType, householdQID })))
        .catch((error) => dispatch(notifyError(error)));
    } catch (error) {
      dispatch(notifyError(error));
      return Promise.resolve(false);
    }
  };
